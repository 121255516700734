import Cookies from 'js-cookie';

let mutations = {
  setDrawer(state, payload) {
    state.drawer = payload
  },
  setToken(state, token) {
    state.token = token;
    Cookies.set('hope4real_token', token, { expires: 7, secure: true, sameSite: 'Strict' });
  },
  setRefreshToken(state, refresh_token) {
    state.refresh_token = refresh_token;
    Cookies.set('hope4real_refresh_token', refresh_token, { expires: 7, secure: true, sameSite: 'Strict' });
  },
  clearUserData(state) {
    state.token = '';
    state.refresh_token = '';

    Cookies.remove('hope4real_token');
    Cookies.remove('hope4real_refresh_token');

    state.username = '';
    state.email = '';
    state.first_name = '';
    state.last_name = '';
    state.line_id = '';
    state.user_id = '';
    state.address = '';
    state.phone_number = '';
    state.roles = [];
  },
  setUserData(state, data) {
    state.username = data.username;
    state.email = data.email;
    state.first_name = data.first_name;
    state.last_name = data.last_name;
    state.line_id = data.line_id;
    state.user_id = data.id;
    state.address = data.address;
    state.phone_number = data.phone_number;
    state.roles = data.roles;
  },
  setAvatar(state, data) {
    state.avatar = data;
  },
  setTitle(state, data) {
    state.title = data;
  },
}
export default mutations
