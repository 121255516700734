<template>
  <v-footer app border>
    <!-- <v-container> -->
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="d-flex flex-column justify-end">
          <div class="body-1 font-weight-light pt-2 text-center">
            ALL RIGHTS RESERVED  &copy; SPI 2025
          </div>
          <div class="body-1 font-weight-light pt-2 text-center">
           <a href="https://www.privacypolicies.com/live/ad41528e-91b5-41db-834d-ee272b462fc4"> PRIVACY POLICY</a> | TERMS AND CONDITIONS
          </div>
        </v-col>
      </v-row>
    <!-- </v-container> -->
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
a {
  color:rgba($color: #000000, $alpha: 0.87)
}
</style>

  