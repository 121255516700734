import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import store from "./store";
import { zhHant, en } from 'vuetify/lib/locale';
import LangEn from './store/lang/en'
import LangZh from './store/lang/zh'
import router from "./router";
// import axios from 'axios'
import apiClient from './utils/apiClient';
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

loadFonts()

function lang() {
  let localLanguage = localStorage.getItem('language')
  if (localLanguage) return localLanguage
  // 默认中文
  else return 'zh'
}

let language = lang()

const i18n = createI18n({
  locale: language,
  fallbackLocale: 'zh',
  messages: {
    'en': { ...en, ...LangEn },
    'zh': { ...zhHant, ...LangZh}
  },
  silentTranslationWarn: process.env.NODE_ENV === 'development'
})



router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.state.token) {
      if (to.fullPath == "/") {
        next({ name: 'Login' })
      } else {
        next({ name: 'Login', query: { redirect: to.fullPath } })
      }
    } else {
      store.commit('setDrawer', true)
      await store.dispatch('getUserData');
      next()
    }

  } else {
    next()
  }
})

createApp(App)
  .use(vuetify)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, apiClient)
  .use(VueTelInput)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
