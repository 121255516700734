import axios from 'axios';
import store from '../store';

// Create Axios instance
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
});

// Request Interceptor: Attach access token
apiClient.interceptors.request.use(
    (config) => {
        const token = store.state.token;
        if (token) {
            config.headers['Content-Type'] = "application/json";
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor: Handle expired token
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.code == "ERR_NETWORK") {
            return Promise.reject(error);
        }
        if (error.response.data.errorCode) {
            return Promise.reject(error);
        }

        // Check if the error is token expiration
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await store.dispatch('refreshAccessToken');
                return apiClient(originalRequest); // Retry the original request
            } catch (refreshError) {
                console.error('Refresh token failed:', refreshError);
                store.dispatch('clearToken');
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default apiClient;