import { createStore } from "vuex";
import mutations from "./mutations"
import actions from "./actions"
import { UserRole } from '../definition'
import Cookies from 'js-cookie'

export default createStore({
    state: {
        drawer: false,
        token: Cookies.get('hope4real_token') || '',
        refresh_token: Cookies.get('hope4real_refresh_token') || '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        line_id: '',
        user_id: '',
        address: '',
        phone_number: '',
        avatar: '',
        roles: [{ id: UserRole.Normal }],
        title: '',
    },
    mutations: mutations,
    actions: actions,
});