// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

// 导入 Vuetify 语言包
import { zhHant, en } from 'vuetify/locale';
import LangEn from '../store/lang/en'
import LangZh from '../store/lang/zh'

// Vuetify
import { createVuetify } from 'vuetify'

const theme = {
  colors:{
    primary: '#4CAF50',
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3',
    ocean: '#2699FB',
    navy: '#4c5fab'
  }
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

  {
    theme: {
      themes: {
        dark: theme,
        light: theme,
      },
    },
    locale: {
      locale: 'zh', // 设置默认语言
      messages: { 
        en : {...en, ...LangEn},
        zh: {...zhHant, ...LangZh}
      }, // 定义可用的语言
    },
    components: {
      VDateInput,
      VTimePicker,
    },
  }
)
